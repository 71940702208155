import React from 'react';

const France = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="57.559"
    height="37.004"
    viewBox="0 0 57.559 37.004"
  >
    <g transform="translate(0 0.001)">
      <rect width="19.186" height="37.004" transform="translate(0 -0.001)" fill="#221b72" />
      <rect width="19.186" height="37.004" transform="translate(19.186 -0.001)" fill="#fff" />
      <rect width="19.186" height="37.004" transform="translate(38.372 -0.001)" fill="#f85743" />
    </g>
  </svg>
);

export default France;
